import Layout from "../../components/layout/Layout";

// This is the homepage
const NotFoundPage = () => {
  return (
    <Layout pageTitle={'404 | jmmisa.ng'}>
      <h1>NotFoundPage</h1>
    </Layout>
  );
};

export default NotFoundPage;
