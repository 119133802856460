import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/home/Home";
import AboutPage from "../pages/about/About";
import ContactPage from "../pages/contact/Contact";
import ServicePage from "../pages/services/Services";
import NotFoundPage from "../pages/error/NotFound";

import CyberSecurityPage from "../pages/cybersecurity/CyberSecurity";
import ITTraining from "../pages/training_service/Training";
import SoftwareDevelopment from "../pages/software_service/Software";
import InformationTecnologyPage from "../pages/informationTecnology/InformationTecnology";


const Routes = () => {
  const pageRoutes = [
    {
      path: "/home",
      element: <HomePage />,
    },
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/about",
      element: <AboutPage />,
    },
    {
      path: "/services",
      children: [
        {
          path: "",
          element: <ServicePage />,
        },
        {
          path: "cybersecurity",
          element: <CyberSecurityPage />,
        },
        {
          path: "information-technology",
          element: <InformationTecnologyPage />,
        },
        {
          path: "training",
          element: <ITTraining />,
        },
        {
          path: "software-development",
          element: <SoftwareDevelopment />,
        },
        {
          path: ":serviceId",
          element: <ServicePage />,
        },
      ],
    },
    {
      path: "/contact",
      element: <ContactPage />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ];
  const router = createBrowserRouter([...pageRoutes]);

  return <RouterProvider router={router} />;
};

export default Routes;
