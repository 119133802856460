export const mission = {
    text:"JM MiSa International Nigeria Limited, a branch of JM MiSa Inc, USA, aims to become a global and highly reputed cyber risk strategist and IT innovator by merging top notch information technology innovation with highly effective, efficient and proactive cyber risk strategy",
    url:"/about"
}

export const vision = {
    text:"Extending organizational capabilities while ensuring incremental profit and operational safety through top notch business and IT inovations and proactive Cyber Risk Strategy",
    url:"/about"
}

export const history = {
    header:"our history",
    paragraphs:[
        "JM MiSa International Nigeria Limited, a branch of JM MiSa Inc, USA, started it's operation back in 2004, becoming an officially registered supplier in 2018. Since then, we have provided critical real-time business-specific services such as splunk distributed single and multiclustered solutions., inlcuding Cybersecurity Splun Engineering/SIEM/SOC/SOAR services to various clients in the Nigeria and other africa countries.",

        "furthermore, our team offers many related servces, including software development, web and mobile application development, information security management, user experience design, network adminstration, business consulting and IT project management. JM Misa also provides and customer experience-oriented solutions to some enterprise in the fianancial and banking sectors"
    ],
    url:"/about"
}

export const weDo = {
    header:"what we do",
    paragraphs:[
        "JM MiSa International Nigeria Limited, a branch of JM MiSa Inc, USA, offers high-end services for both IT consulting, IT solutions and complete training. To ensure the best services, we often partners with some of the top firms around the world. With their support, our team delivers excellent service quality that utilizes modern innovations to various customers. For JM Misa, the client's requirement for the Information Thecnology solutions is the prime consideration.",

        "As such, we offer the right solution in minimal time to meet all deadlines set by clients, Don't worry! our proffesional team ensures that everything remains within your budget. Due to this directed approach, we have mustered good faith and a reputation for our quality services. JM Misa Inc believes that excellence is the product of quality, time management, innovation, and customer-focused service that can provide the best value for money."
    ],
    url:"/about"
}