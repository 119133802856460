import { services } from "../../utils/data/home-data";

import React from "react";
import WhoWeAre from "./WhoWeAre";
import Services from "./Services";

const Home = () => {
  return (
    <>
      <WhoWeAre />
      <Services services={services} />
    </>
  );
};

export default Home;
