import logo from "../../assets/images/logo.png";

const Logo = () => {
  return (
    <div>
      <img className="w-[120px]" src={logo} alt="logo" />
    </div>
  );
};

export default Logo;
