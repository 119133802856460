import React from 'react'
import Layout from '../../components/layout/Layout'
import HeroBanner from '../../components/Hero'
import banner from "../../assets/images/Rectangle17.png";
import InformationTecnology from '../../components/InformationTecnology/InformationTecnology';
const InformationTecnologyPage = () => {
    return (
        <Layout pageTitle={'Services - Information Technology | jmmisa.ng'}>
        {/* banner section */}
        <HeroBanner
          title="Information Technology"
          subText="In the realm of information technology, we offer a spectrum of inovative solutions."
          showButton={false}
          bannerImage={banner}
          gradient="light"
        />
        <InformationTecnology />
      </Layout>
    )
}

export default InformationTecnologyPage
