import Home from "../../components/home/Home";
// assets import
import banner from "../../assets/images/homepage_banner.png";

import HeroBanner from "../../components/Hero";
import Layout from "../../components/layout/Layout";

const HomePage = () => {
  return (
    <Layout pageTitle={'Home | jmmisa.ng'}>
      {/* banner section */}
      <HeroBanner
        title="CYBER SECURITY & IT SOLUTIONS"
        subText="Extending organizational capabilities while ensuring incremental profit and operational safety."
        buttonText="Check Our Services"
        bannerImage={banner}
        navigateTo={'/services'}
        gradient="light"
      />
      <Home />
    </Layout>
  );
};

export default HomePage;
