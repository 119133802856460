import { SiFacebook } from "react-icons/si";
import { GrLinkedin } from "react-icons/gr";
import { IoLogoYoutube } from "react-icons/io";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";

export const about =
  "JM MiSa provides critical real-time business-specific services such as Splunk distributed, and single- and multi-clustered solutions, including Cybersecurity Splunk Engineering/SIEM/SOC/SOAR services to various clients in Nigeria and other African countries.";

export const address = {
  header: "Address",
  addr: "Suite 33 Amaden Plaza Mabushi Abuja",
  email: "info@jmmisa.com",
  phone: "+2349035518652",
  work: "Work Days: Mon - Fri",
  time: "Time: 9am - 5pm",
};

export const footerLinks = [
  {
    id: 1,
    header: "services",
    links: [
      {
        title: "software development",
        url: "/services/software-development",
      },
      {
        title: "information technology",
        url: "/services/information-technology",
      },
      {
        title: "cybersecurity & SIEM engineering",
        url: "/services/cybersecurity",
      },
      {
        title: "IT training",
        url: "/services/training",
      },
      {
        title: "Security Operations",
        url: "/",
      },
    ],
  },
  {
    id: 2,
    header: "quick links",
    links: [
      {
        title: "home",
        url: "/",
      },
      {
        title: "about us",
        url: "/about",
      },
      {
        title: "services",
        url: "/services",
      },
      {
        title: "contact us",
        url: "/contact",
      },
    ],
  },
];

export const footerIcons = [
  {
    icon: <SiFacebook size={18} className="text-[#33709E]" />,
    url: "https://www.facebook.com/profile.php?id=61559932612513&mibextid=LQQJ4d",
  },
  {
    icon: <GrLinkedin size={18} className="text-[#33709E]" />,
    url: "",
  },
  {
    icon: <IoLogoYoutube size={18} className="text-[#33709E]" />,
    url: "",
  },
  {
    icon: <FaSquareXTwitter size={18} className="text-[#33709E]" />,
    url: "https://x.com/jmisa_ng?s=21",
  },
  {
    icon: <FaInstagram size={18} className="text-[#33709E]" />,
    url: "https://www.instagram.com/jmisa_ng?igsh=MXhlZmt0bDlrYTN4Zw==",
  },
];
