import React from "react";
import { whoWeAre } from "../../utils/data/home-data";
import Ellipse118 from "../../assets/images/Ellipse118.png";
import { TbDeviceDesktopCode } from "react-icons/tb";
import { SlScreenSmartphone } from "react-icons/sl";
import { IoManOutline } from "react-icons/io5";
import { LiaUsersSolid } from "react-icons/lia";

const WhoWeAre = () => {
  return (
    <div className="relative overflow-hidden">
      <div className="pt-[65px] pb-[50px] w-[87%] mx-auto">
        <div className="flex justify-between">
          <div className="w-full sm:w-[44%] md:w-[50%] lg:w-[60%]">
            {whoWeAre && (
              <>
                <h1 className="font-[500] text-[35px] text-[#33709E] my-3 font-sans">
                  {whoWeAre.header}
                </h1>
                <h5 className="font-[500] text-[18px] text-[#33709E] mb-3 font-sans">
                  {whoWeAre.subHeading}
                </h5>

                {whoWeAre?.paragraphs?.map((paragraph, index) => (
                  <p className="mb-4" key={index}>
                    {paragraph}
                  </p>
                ))}
              </>
            )}
          </div>
          <div className="hidden sm:block">
            <img
              src={Ellipse118}
              alt="eclipse118"
              className="sm:w-[480px] md:w-[520px] lg:w-[620px] absolute top-[50px] right-[-110px] z-[-1]"
            />
          </div>
        </div>

        <div className="md:w-[80%] lg:w-[65%] my-5">
          <div className="py-[40px] grid grid-cols-1  md:grid-cols-2  lg:grid-cols-4  place-items-center bg-white shadow-xl border-0 rounded-md">
            <div className="flex flex-col items-center w-[23%] my-2">
              <TbDeviceDesktopCode size={45} className="text-[#33709E]" />
              <h5 className="text-[#33709E] font-[600] text-center my-3">
                44 +
              </h5>
              <p className="font-[400] text-[13px] text-nowrap">Softwares</p>
            </div>
            <div className="flex flex-col items-center w-[23%] my-2">
              <SlScreenSmartphone size={45} className="text-[#33709E]" />
              <h5 className="text-[#33709E] font-[600] text-center my-3">
                25 +
              </h5>
              <p className="font-[400] text-[13px] text-nowrap">Applications</p>
            </div>
            <div className="flex flex-col items-center w-[23%] my-2">
              <IoManOutline size={45} className="text-[#33709E]" />
              <h5 className="text-[#33709E] font-[600] text-center my-3">
                500 +
              </h5>
              <p className="font-[400] text-[13px] text-nowrap">
                Active Clients
              </p>
            </div>
            <div className="flex flex-col items-center w-[23%] my-2">
              <LiaUsersSolid size={45} className="text-[#33709E]" />
              <h5 className="text-[#33709E] font-[600] text-center my-3">
                18 +
              </h5>
              <p className="font-[400] text-[13px] text-nowrap">Teams</p>
            </div>
          </div>

          <div className="w-[200px] h-[200px] bg-[#b7d8f3] rounded-full absolute bottom-[42px] left-[-10px] z-[-1]"></div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
