export const training_details = [
  {
    title: "We offer an on-the-job support for 6 months for all our students.",
    paragragh:
      "This features comes with a fee of $18,000 spread over 12 months, payment starts when you secure an employment. Contact us for more details. We also offer interview preparation and support for 30 days at $250 one-time payment. Our support starts from the day you graduated from our training.",
    subTitle:"What we offer – included in the training fee Payment plans (contact us for details)",
    features: [
      "Work experience with JM MiSa Inc (internship program)",
      "Resume and LinkedIn profile clean up",
      "Certification exams review",
    ],
    url:"/services/training"
  },
  {
    title: "Class Features:",
    features: [
      "Live support – reach out to the instructor 24/7",
      "Hands-on experience",
      "Projects, and Review",
    ],
    url:"/services/training"
  },
  {
    title: "Method of Teaching:",
    features: [
      "Virtual Classroom",
      "All time in CST Timezone (in case you would prefer another time period, please let us know, we may be able to adjust to your time)",
      "Saturday: 7:30pm – 9pm CST",
      "Sunday: 9am – 12pm | 4pm – 7pm",
    ],
    url:"/services/training"
  },
];
