import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { scrollToTop } from "../../utils/helperFunctions";
import { history, mission, vision, weDo } from "../../utils/data/about-data";
import { services } from "../../utils/data/home-data";
import { SIEM, dataProtection } from "../../utils/data/cybersecurity-data";
import { infoItems } from "../../utils/data/informationTech-data";
import { software_features } from "../../utils/data/software_data";
import { training_details } from "../../utils/data/training-data";
import DisplaySearchdata from "./DisplaySearchdata";
import { filterData } from "../../utils/helperFunctions/helperFunctions";


const Layout = ({ children, pageTitle }) => {

  const [searchData, setSearchData] = useState(null);
  const [term, setTerm] = useState('');


  const performSearch  = (term)=>{
    if(!term){
      setSearchData(null);
      return
    }
    
    setTerm(term);
    const lowercasedTerm = term.toLowerCase();

    const filteredMission = filterData(lowercasedTerm, mission);
    const filteredVision = filterData(lowercasedTerm, vision);
    const filteredServices = filterData(lowercasedTerm, services);
    const filteredHistory = filterData(lowercasedTerm, history);
    const filteredWeDo = filterData(lowercasedTerm, weDo);
    const filteredDataProtection = filterData(lowercasedTerm, dataProtection);
    const filteredSIEM = filterData(lowercasedTerm, SIEM);
    const filteredInfoItems = filterData(lowercasedTerm, infoItems);
    const filteredSoftwareFeatures = filterData(lowercasedTerm, software_features);
    const filteredTrainingDetails = filterData(lowercasedTerm, training_details);
    
    if(
      filteredServices ||
      filteredMission ||
      filteredVision ||
      filteredHistory ||
      filteredWeDo ||
      filteredDataProtection ||
      filteredSIEM ||
      filteredInfoItems ||
      filteredSoftwareFeatures ||
      filteredTrainingDetails
    ){
      setSearchData([
        ...filteredServices,
        ...filteredMission,
        ...filteredVision,
        ...filteredHistory,
        ...filteredWeDo,
        ...filteredDataProtection,
        ...filteredSIEM,
        ...filteredInfoItems,
        ...filteredSoftwareFeatures,
        ...filteredTrainingDetails,
    ]);
    
    }else{
      setSearchData({})
    }

  }

  useEffect(()=>{
    scrollToTop();
    document.title = pageTitle
  }, [pageTitle]);

  return (
    <div className="relative overflow-y-hidden ">
      <Header onSearch={performSearch}/>
      {children}

      {
        searchData && <DisplaySearchdata term={term} data={searchData}/>
      }
      <Footer />
    </div>
  );
};

export default Layout;
