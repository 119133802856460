import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const ServiceCard = ({
  icon,
  subHeading,
  paragraphs,
  linkTitle,
  url,
}) => {
  return (
    <>
      <div className="rounded-lg shadow-md p-10 hover:border hover:border-[#33709E] bg-white cursor-pointer">
        {icon}
        <h5 className="font-[500] text-[18px] text-[#33709E] mb-3 font-sans">
          {subHeading}
        </h5>
        <p className="font-[400] text-[15px] mb-5">{paragraphs}</p>

        { linkTitle && <Link to={url} className="text-[#33709E] font-[500]">
          {linkTitle} <BsArrowRight size={18} className="inline ml-1" />
        </Link>}
      </div>
    </>
  );
};

export default ServiceCard;
