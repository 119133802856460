import React from "react";
import { history, mission, vision, weDo } from "../../utils/data/about-data";
import { GoRocket } from "react-icons/go";
import { IoTelescopeOutline } from "react-icons/io5";
import Rectangle13 from "../../assets/images/Rectangle13.png";
import Rectangle14 from "../../assets/images/Rectangle14.png";

const About = () => {
  return (
    <div className="w-full">
      <div className="w-[87%] mx-auto pt-[8rem] pb-[6rem]">
        <div className="flex flex-col md:flex-row mb-[65px]">
          <div
            className="bg-[#33709E] rounded-tl-lg md:rounded-bl-lg rounded-tr-lg 
            md:rounded-tr-none md:w-[35%]  flex justify-center items-center md:mr-5 py-2 transition-all ease-out md:hover:scale-[1.08]"
          >
            <h3 className="text-white capitalize font-[600] text-[20px]">
              our mission
            </h3>
          </div>
          <div
            className="bg-[#f5faff] md:rounded-tr-lg rounded-br-lg md:w-[60%] 
            md:h-[180px] flex justify-center items-center p-6 transition-all 
            ease-out md:hover:scale-[1.08]"
          >
            <div className="rounded-md bg-[#e5f0fc] p-3 md:p-6 mr-1 md:mr-4">
              <GoRocket size={50} className="text-[#33709E]" />
            </div>
            <p
              className="px-3 font-[400] font-sans w-full md:w-[90%] text-[0.78rem]
                 sm:text-[0.81rem] 800px:text-[1rem]"
            >
              {mission.text}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row mb-10 md:justify-end">
          <div
            className="bg-[#33709E] rounded-tl-lg md:rounded-bl-lg rounded-tr-lg
             md:rounded-tr-none md:w-[35%] flex justify-center items-center md:mr-5 
             py-2 transition-all ease-out md:hover:scale-[1.08]"
          >
            <h3 className="text-white capitalize font-[600] text-[20px]">
              our vision
            </h3>
          </div>
          <div
            className="bg-[#f5faff] md:rounded-tr-lg rounded-br-lg md:w-[60%] h-[200px]
             flex justify-center items-center p-6 transition-all ease-out md:hover:scale-[1.08]"
          >
            <div className="rounded-md bg-[#e5f0fc] p-3 md:p-6 mr-1 md:mr-4">
              <IoTelescopeOutline size={50} className="text-[#33709E]" />
            </div>
            <p
              className="px-3 font-[400] font-sans w-full :sm:w-[90%] text-[0.78rem]
                 sm:text-[0.81rem] 800px:text-[1rem]"
            >
              {vision.text}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mx-auto pt-[8rem] pb-[6rem] bg-[#487AA8]">
        <div
          className="w-[87%] mx-auto flex flex-col items-center 
        1200px:flex-row 1200px:justify-between mb-[13.5rem] 1200px:mb-[7rem] "
        >
          <div
            className="relative bg-gradient-to-r from-[#33709E] via-[#487AA8] to-[#BC84F3] 
          rounded-lg w-[80%] h-[180px] 400px:h-[200px] sm:h-[300px] md:h-[380px] 800px:h-[450px] 
          lg:h-[550px] 1200px:w-[460px] 1200px:h-[360px]"
          >
            <img
              src={Rectangle13}
              alt=""
              className="absolute top-[3rem] 800px:top-[3.75rem] left-[2.5rem] sm:left-[3rem] 
            800px:left-[4rem] lg:left-[6rem] 1200px:left-[3.75rem]"
            />
          </div>

          <div className="w-[80%] order-first 1200px:order-last 1200px:w-[50%]">
            <h1
              className="capitalize text-gray-50 font-sans font-[600] 
            text-[2.25rem] mb-4"
            >
              {history.header}
            </h1>

            {history?.paragraphs &&
              history?.paragraphs.map((paragraph, i) => (
                <p
                  key={i}
                  className="text-gray-50 first-letter:capitalize mb-6"
                >
                  {paragraph}
                </p>
              ))}
          </div>
        </div>

        <div
          className="w-[87%] mx-auto flex flex-col items-center 
        1200px:flex-row 1200px:justify-between mb-[6.5rem]"
        >
          <div className="w-[80%] 1200px:w-[50%]">
            <h1
              className="capitalize text-gray-50 font-sans font-[600] 
            text-[2.25rem] mb-4"
            >
              {weDo.header}
            </h1>

            {weDo?.paragraphs &&
              weDo?.paragraphs.map((paragraph, i) => (
                <p
                  key={i}
                  className="text-gray-50 first-letter:capitalize mb-6"
                >
                  {paragraph}
                </p>
              ))}
          </div>

          <div
            className="relative bg-gradient-to-r from-[#33709E] via-[#487AA8] to-[#BC84F3]
           rounded-lg w-[80%] h-[180px] 400px:h-[200px] sm:h-[300px] md:h-[380px] 800px:h-[450px] 
           lg:h-[550px] 1200px:w-[460px] 1200px:h-[360px]"
          >
            <img
              src={Rectangle14}
              alt=""
              className="absolute top-[3rem] 800px:top-[3.75rem] 
            left-[2.5rem] sm:left-[3rem] 800px:left-[4rem] lg:left-[6rem] 1200px:left-[3.75rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
