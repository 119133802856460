export const ContactEmailTemplate = (data) => {
  const emailData = `
        <html lang="en">
    <head>
  
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Monoton&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&family=Quicksand:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
    </head>
    <body>
      <style>
        body {
          font-family: "Quicksand", sans-serif;
        }
        h1 {
          text-align: center;
          background-color: #410603;
          color: white;
          padding: 0.8rem;
          font-weight: 200;
          font-size: 1.5rem;
        }
        h1 span {
          font-weight: 900;
        }
        div {
          font-size: 0.8rem;
          border: 2px solid #eee;
          padding: 0.8rem;
          border-radius: 10px;
        }
        b {
          letter-spacing: 3px;
        }
      </style>
      <h1><span>JM MISA SUPPORT</span></h1>
      <div class="">
        <h2>From ${data.fullName}</h2>
        <p>
        ${data.message}
      
        </p>
      
      </div>
    </body>
  </html>
  
      `;

  return emailData;
};
