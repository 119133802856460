import { Icon } from "@iconify/react";
// import { BsShieldLock } from "react-icons/bs";

const clientIcon = (
  <Icon
    icon="arcticons:rdclient"
    className="text-primary-default "
    fontWeight={800}
    width="3rem"
  />
);
const securityIcon = (
  <Icon
    icon="oui:app-security"
    className="text-primary-default "
    fontWeight={700}
    width="3rem"
  />
);

const homeLockIcon = (
  <Icon
    icon="mage:home-security-lock"
    className="text-primary-default "
    fontWeight={700}
    width="3rem"
  />
);

const trainingIcon = (
  <Icon
    icon="oui:training"
    className="text-primary-default "
    fontWeight={700}
    width="3rem"
  />
);

const clusterIcon = (
  <Icon
    icon="carbon:software-resource-cluster"
    className="text-primary-default "
    fontWeight={700}
    width="3rem"
  />
);
export const services_data = {
  header: "Services",
  subHeading: "Splunk And Information Security Solutions",
  paragraph:
    "JM MiSa International Nigeria Limited, a branch of JM MiSa Inc, USA, deals with both training and development of both web and mobile application software",
  url: "/services",
  serviceItems: [
    {
      icon: clientIcon,
      subHeading: "Information Technology",
      paragraph:
        "Our staff are highly skilled with many years of active professional engagement in their domains of practice. Using state of the art technological tools and highly secured algorithms, we help organizations in various aspect of Information Technology requirements. We represent a one-stop shop for web and mobile application development, software development, content management, quality assurance and even training.",
      url: "/services/information-technology",
    },
    {
      icon: securityIcon,
      subHeading: "Cybersecurity & SIEM Engineering",
      paragraph:
        "The modern, globalized world operates mostly on the internet. Every business has an online presence and uses it to create a brand image and gain customers. However, such involvement with digital data also raises the requirement for data protection and safety. Otherwise, the business may face numerous data breaches, malware, hacking, etc. Here, JM MISA, being the prime IT contractors in the US and Canada, has compiled a list of reasons why security solutions and cybersecurity are important for firms today.",
      url: "/services/cybersecurity",
    },
    {
      icon: trainingIcon,
      subHeading: "IT Training",
      paragraph:
        "An IT training department in JM MiSa Inc which offers training in various IT Domains. Our training style is very much similar to live campus method as it involves, labs, projects, reviews and grading to measure progress. Our grading system does not qualifies who is better, but measures only how individuals are progressing in the acquisition of work-ready knowledge. All classes in JM MiSa Inc are based on Virtual Live Instructor (VLI) methods of teaching.",
      url: "/services/training",
    },
    {
      icon: homeLockIcon,
      subHeading: "Enterprise Security",
      paragraph:
        "JM MiSa International Nigeria Limited enables companies to take charge of their security. We are in partnership with global IT Security leaders to ensure that we can deliver on our promise of impeccable security services. Our professionals are EC-Council trained and certified with more than 30 years of active experience in fighting global cyber attacks.",
      url: "/services/enterprise-security",
    },
    {
      icon: clusterIcon,
      subHeading: "Custom Software Development",
      paragraph:
        "Our software applications are meticulously crafted to meet the specific needs of financial services, insurance, and fintech companies. Whether you're seeking to streamline operations, enhance customer experiences, or innovate within your industry, our solutions are designed to empower your business.",
      url: "/services/software-development",
    },
  ],
};
