import React from 'react'

import { infoItems } from '../../utils/data/informationTech-data'
import ImageCard from '../imagecard/ImageCard'
import CallToActionBanner from '../CallToActionBanner'

const InformationTecnology = () => {
  return (
    <div className='w-full'>
      <div className='w-[87%] mx-auto '>
        <div className='items-stretch w-full grid grid-cols-1 gap-10  830px:grid-cols-2 830px:w-[770px] lg:w-[910px] 1200px:w-[1010px] mx-auto 1700px:grid-cols-3 1700px:w-[1515px]'>
          {
            infoItems && infoItems.map((item, index)=>(
            <ImageCard
                key={index}
                bg={item.bg}
                header={item.header}
                subHeading={item.subHeading}
                paragraph={item.paragraph}
                url={item.url}
                linkTitle={item.urlTitle}
            />

            ))
          }
        </div>
      </div>

      <CallToActionBanner />
    </div>
  )
}

export default InformationTecnology
