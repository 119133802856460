import { useNavigate } from "react-router";
import calltoaction_banner_image from "../assets/images/calltoaction_banner.png";

import ButtonComponent from "./tags/Button";

const CallToActionBanner = ({
  navigateTo = "/contact",
  navigateFunction,
}) => {
  const navigate = useNavigate();
  const defaultButtonNavigation = () => {
    navigate(navigateTo);
  };

  return (
    <section className="flex h-64 relative text-white  items-center text-center justify-center flex-col">
      <div
        className="absolute top-0 left-0 right-0 bottom-0 "
        style={{
          backgroundImage: `url(${calltoaction_banner_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center 10%",
        }}
      ></div>
      md:{" "}
      <d
        text-xliv
        className={`absolute top-0 left-0 right-0 bottom-0  inset-0 bg-gradient-to-b "bg-gradient-to-b from-[#07252C] to-[#07252C] opacity-90   `}
      ></d>
      <div className="z-30  md:max-w-[50vw] max-w-[90vw] flex flex-col items-center justify-center gap-5">
        <h1 className="capitalize   md:text-3xl text-xl font-bold">
          Get Your Free Consultation
        </h1>
        <h2 className="font-semibold text-normal">
          Please use the button below to contact us.You can fill the form and
          submit your details and we will surely get back to you.
        </h2>
        <ButtonComponent 
        title="Enroll Now" 
        type="light" 
        clickHandler={
          navigateFunction ? navigateFunction : defaultButtonNavigation
        }
        />
      </div>
    </section>
  );
};

export default CallToActionBanner;
