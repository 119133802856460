import { useState } from "react";

import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { HiMenuAlt3 } from "react-icons/hi";

import Logo from "./logo";
import { navbarLinks } from "../../utils/data/header-data";
import { NavLink, useLocation } from "react-router-dom";
import { scrollToTop } from "../../utils/helperFunctions";
import Search from "./Search";

const RenderSubNavigaton = ({ 
  subRoutes, 
  open, 
  setOpen, 
}) => {

  


  return (
    <>
      <ul
        className={`absolute text-black top-10 z-50  md:w-48 bg-white shadow-md p-4 flex flex-col gap-6 rounded-xl transition-all duration-800 transform overflow-hidden ${
          open
            ? "opacity-100  translate-y-0"
            : "opacity-0  -translate-y-[30rem]"
        }`}
      >
        {subRoutes.map((subRoute) => {
          return (
            <li
              onClick={() => {
                setOpen(!open);
                scrollToTop();
              }}
              key={subRoute.name}
              className="text-sm "
            >
              <NavLink
                className="hover:font-medium shrink-1 hover:text-primary-default relative before:w-[0%] before:h-[2px] before:bg-primary-default before:block before:absolute before:bottom-[-3px] hover:before:w-[85%] hover:before:transition-all ease-out hover:before:duration-[1s] "
                to={subRoute.route}
              >
                {subRoute.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const MobileNavigationBar = ({ openMobileNav, setOpenMobileNav }) => {
  return (
    <div className="flex gap-4 px-3 bg-white items-center justify-between">
      <span>
        <Logo />
      </span>
      <span
        onClick={() => setOpenMobileNav(!openMobileNav)}
        className="text-primary-default"
      >
        <HiMenuAlt3 size="1.5rem" />
      </span>
    </div>
  );
};

const Header = ({onSearch}) => {
  const location = useLocation().pathname;

  const [isFixed, setIsfixed] = useState(false)

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setIsfixed(true);
    } else {
      setIsfixed(false);
    }
  })

  const [openSubNav, setOpenSubNav] = useState(false);
  const [openMobileNav, setOpenMobileNav] = useState(false);

  
  return (
    <>
      {/* Desktop View */}
      <header className={`${isFixed ? 'fixed top-0 left-0 bg-white w-full z-50' : ''} z-10 md:flex hidden  sm:px-[73px]  py-3 items-center justify-between shadow-sm`}>
        <div>
          <Logo />
        </div>
        <nav>
          <ul className={`flex items-center gap-10 text-primary-default  `}>
            {navbarLinks.map((route) => {
              return (
                <li className="relative" key={route.name}>
                  <span
                    onClick={scrollToTop}
                    className="flex items-center gap-3 justify-center"
                  >
                    <NavLink
                      className={`hover:font-medium hover:text-primary-dark before:w-[0%] before:h-[2px] before:bg-primary-default before:block before:absolute before:bottom-[-3px] hover:before:w-[85%] hover:before:transition-all ease-out hover:before:duration-[1s] ${
                        location === route.route
                          ? "text-primary-dark font-semibold"
                          : "text-primary-default"
                      }  `}
                      to={route.route}
                    >
                      {route.name}
                    </NavLink>
                    {route.subRoutes.length > 0 && (
                      <span
                        onClick={() => setOpenSubNav(!openSubNav)}
                        className="cursor-pointer"
                      >
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    )}
                  </span>

                  {route.subRoutes.length > 0 && (
                    <RenderSubNavigaton
                      subRoutes={route.subRoutes}
                      open={openSubNav}
                      setOpen={setOpenSubNav}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
        <Search onSearch={onSearch}/>
      </header>

      {/* Mobile View */}
      <header className={`${isFixed ? 'fixed top-0 left-0 bg-white w-full z-50' : ''} top-0 left-0 block md:hidden`}>
        <MobileNavigationBar
          openMobileNav={openMobileNav}
          setOpenMobileNav={setOpenMobileNav}
        />
        <aside
          className={`bg-primary-default ${
            openMobileNav
              ? "fixed top-0 z-50 left-0 block bottom-0 right-0"
              : "hidden"
          }  text-white`}
        >
          <MobileNavigationBar
            openMobileNav={openMobileNav}
            setOpenMobileNav={setOpenMobileNav}
          />
          <nav>
            <ul className="flex flex-col p-3  gap-10 ">
              {navbarLinks.map((route) => {
                return (
                  <li className="relative" key={route.name}>
                    <span
                      onClick={scrollToTop}
                      className=" flex items-center gap-3"
                    >
                      <NavLink className="" to={route.route}>
                        {route.name}
                      </NavLink>
                      {route.subRoutes.length > 0 && (
                        <span
                          onClick={() => setOpenSubNav(!openSubNav)}
                          className="cursor-pointer"
                        >
                          <MdOutlineKeyboardArrowDown />
                        </span>
                      )}
                    </span>

                    {route.subRoutes.length > 0 && (
                      <RenderSubNavigaton
                        subRoutes={route.subRoutes}
                        open={openSubNav}
                        setOpen={setOpenSubNav}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        </aside>
      </header>
    </>
  );
};

export default Header;
