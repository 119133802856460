import software_section from "../../assets/images/software_section.png";

import HeroBanner from "../../components/Hero";
import SectionBanner from "../../components/SectionBanner";
import Layout from "../../components/layout/Layout";
import ServiceCard from "../../components/servicecard/ServiceCard";
import { software_features } from "../../utils/data/software_data";

const SoftwareDevelopment = () => {
  return (
    <Layout pageTitle={'Services - Software Development | jmmisa.ng'}>
      <HeroBanner
        showButton={false}
        title="CUSTOM SOFTWARE DEVELOPMENT"
        bannerImage={software_section}
        subText="We offer software applications tailored to various financial services, insurance, fintech companies, and more."
      />
      <div className="my-20">
        <SectionBanner
          bannerImg={software_section}
          title="Custom Software Development"
          content="Regardless of whether technology is central to your company's business model, investing in superior software can provide a significant competitive edge. Custom software has the potential to enhance innovation, profitability, and efficiency across various industries. As a Custom Software Development Company, we possess the expertise and resources to create efficient solutions that drive innovation and profitability for our clients."
        />
      </div>
      <div className="md:max-w-[85vw] max-w-[100vw]  mx-auto mb-20 ">
        <div className="w-full grid grid-cols-1 gap-10  830px:grid-cols-2 830px:w-[770px] lg:w-[910px] 1200px:w-[1010px] mx-auto 1700px:grid-cols-3 1700px:w-[1515px]">
          {software_features.map((feature, index) => {
            return (
              <ServiceCard
                key={feature.subHeading}
                icon={feature.icon}
                paragraphs={feature.paragraph}
                subHeading={feature.subHeading}
                hasLink={false}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default SoftwareDevelopment;
