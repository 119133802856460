import { useNavigate } from "react-router";
import ButtonComponent from "./tags/Button";

const gradientTypes = {
  light: "bg-gradient-to-b from-[#041D30] to-[#33709E] opacity-50",
  dark: "bg-gradient-to-b from-[#01121E] to-[#01121E] opacity-80",
};

const HeroBanner = ({
  title,
  subText,
  buttonText,
  bannerImage,
  showButton = true,
  gradient = "dark",
  navigateTo = "/contact",
  navigateFunction,
}) => {
  const navigate = useNavigate();
  const defaultButtonNavigation = () => {
    navigate(navigateTo);
  };
  return (
    <section className="flex md:h-[90vh] h-screen relative text-white  items-center text-center justify-center flex-col">
      <div
        className="absolute top-0 left-0 right-0 bottom-0 "
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center 10%",
        }}
      ></div>
      <div
        className={`absolute top-0 left-0 right-0 bottom-0  inset-0 bg-gradient-to-b ${gradientTypes[gradient]}   `}
      ></div>
      <div className="z-30  md:max-w-[50vw] max-w-[90vw] flex flex-col items-center justify-center gap-5">
        <h1 className="uppercase  md:text-4xl text-3xl font-bold">{title}</h1>
        <h2 className="font-semibold text-normal">{subText}</h2>
        {showButton && (
          <ButtonComponent
            clickHandler={
              navigateFunction ? navigateFunction : defaultButtonNavigation
            }
            title={buttonText}
            type="light"
          />
        )}
      </div>
    </section>
  );
};

export default HeroBanner;
