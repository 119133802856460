import { ContactEmailTemplate } from "../template/contact_email";

export const sendContactEmail = async (data) => {
  const emailBody = ContactEmailTemplate(data);
  const key = `${process.env.REACT_APP_EMAIL_KEY}`;
  const url = `${process.env.REACT_APP_EMAIL_URL}`;

  const mailData = {
    name: "JM MISA SUPPORT",
    sender: { name: data.fullName, email: data.email },
    to: [{ email: "info@jmmisa.com" }],
    subject: "Contact Us Email",
    htmlContent: emailBody,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": key,
      },
      body: JSON.stringify(mailData),
    });
    const result = await response.json();

    // window.location.replace("/");

    return result;
  } catch (err) {
    console.log(err);
  }
};
