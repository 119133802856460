export const navbarLinks = [
  {
    name: "Home",
    route: "/",
    subRoutes: [],
  },
  {
    name: "About",
    route: "/about",
    subRoutes: [],
  },
  {
    name: "Services",
    route: "/services",
    subRoutes: [
      {
        name: "Software Development",
        route: "/services/software-development",
      },
      {
        name: "Information Technology",
        route: "/services/information-technology",
      },
      {
        name: "Cybersecurity & SIEM Engineering",
        route: "/services/cybersecurity",
      },
      {
        name: "IT Training",
        route: "/services/training",
      },
      {
        name: "Security Operations",
        route: "/services/security-operations",
      },
    ],
  },
  {
    name: "Contact",
    route: "/contact",
    subRoutes: [],
  },
];
