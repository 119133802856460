const background_type = {
  light: "bg-transparent text-black",
  dark: "bg-black text-white",
};

const SectionBanner = ({ content, title, bannerImg, background = "dark" }) => {
  return (
    <section className={`${background_type[background]} md:p-16 p-8 `}>
      <div className="md:max-w-[70vw]   mx-auto md:flex items-center gap-10 justify-center">
        <div className="flex-1 ">
          <div className=" relative h-96 md:w-[28rem] ">
            <div className="section_banner_backdrop md:w-96 w-80 absolute top-0 rounded-xl bottom-12 left-0  "></div>
            <div className=" md:w-96  w-80 bg-white absolute top-12 right-0 bottom-0 z-10 rounded-xl">
              <img
                className="object-cover w-full h-full rounded-xl"
                src={bannerImg}
                alt="protection_section"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col md:my-0 my-10 gap-4">
          <h1 className="font-bold text-2xl">{title}</h1>
          <p className="font-light text-base">{content}</p>
        </div>
      </div>
    </section>
  );
};

export default SectionBanner;
