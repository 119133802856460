import { BsShieldLock } from "react-icons/bs";
import { TbDeviceDesktopCode } from "react-icons/tb";
import { MdOutlineGames } from "react-icons/md";
import { TfiLayoutMediaCenterAlt } from "react-icons/tfi";
import { MdModelTraining } from "react-icons/md";

const shieldIcon = <BsShieldLock size={45} className="text-[#33709E] mb-4" />;
const deviceDesktop = (
  <TbDeviceDesktopCode size={45} className="text-[#33709E] mb-4" />
);
const gameIcon = <MdOutlineGames size={45} className="text-[#33709E] mb-4" />;
const infoTech = (
  <TfiLayoutMediaCenterAlt size={45} className="text-[#33709E] mb-4" />
);
const modelTraining = (
  <MdModelTraining size={45} className="text-[#33709E] mb-4" />
);

export const whoWeAre = {
  header: "Who We Are",
  subHeading: "Cyber Security & Splunk SIEM/SOC/SOAR Solutions",
  paragraphs: [
    "Our firm focuses on high-end IT solution services, offering you the best IT solution related to Splunk, website development, mobile development, enterprise security, cybersecurity, and many more! Work with us to get the most sophiscated solutions suited to all your business requirements.",

    "Our team of professionals believes in providing the best Information Technology outsourcing, offering all local and multinational client the best IT solutions. For us, being the IT contractors who can help you meet your business goal is the priority",

    "With JM MiSa International Nigeria Limited, your success will have no limits. Offering critical, real-time business specific services ranging from Splink distributions, clustered solutions, and all forms of SIEM, SOC and SOAR services.",
  ],
};

export const services = {
  header: "Services",
  subHeading: "Splunk And Information Security Solutions",
  paragraph:
    "JM MiSa International Nigeria Limited, a branch of JM MiSa Inc, USA, deals with both training and development of both web and mobile application software",
  serviceItems: [
    {
      icon: shieldIcon,
      subHeading: "Security Solutions",
      paragraph:
        "In today's digital age, protecting data from breaches, malware, and hacking is crucial. JM MISA provides comprehensive security solutions to safeguard your business and data.",
    },
    {
      icon: deviceDesktop,
      subHeading: "Web/Mobile Development",
      paragraph:
        "We provide expert IT services, including web/mobile app development, content management, and quality assurance, using cutting-edge technology and secure algorithms.",
      url: "/services/software-development",
    },
    {
      icon: gameIcon,
      subHeading: "Custom Software Development",
      paragraph:
        "We develop tailored software solutions for financial, insurance, and fintech companies, helping streamline operations and enhance customer experiences.",
      url: "/services/cybersecurity",
    },
    {
      icon: modelTraining,
      subHeading: "IT Training",
      paragraph:
        "JM MISA offers IT training with hands-on labs, projects, and assessments to ensure practical, work-ready knowledge through virtual live instructor methods.",
      url: "/services/training",
    },
    {
      icon: infoTech,
      subHeading: "Information Technology",
      paragraph:
        "We deliver innovative IT solutions that enhance business efficiency and security. Our team specializes in system integration, cloud services, and technical support tailored to your organization's needs.",

      url: "/services/information-technology",
    },
  ],
};
