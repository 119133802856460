export const shortenText = (text, len) => {
  if (text.length > len) {
    const shortenText = text.substring(0, len).concat("...");

    return shortenText;
  }
  return text;
};

export const filterData = (lowercasedTerm, data) => {
  const result = [];

  if (Array.isArray(data)) {
    for (let item = 0; item < data.length; item++) {
      const filteredItem = filterData(lowercasedTerm, data[item]);
      if (filteredItem) {
        result.push(...filteredItem);
      }
    }
  } else if (typeof data === 'object') {
    for (let key in data) {
      if (Array.isArray(data[key])) {
        filterData(lowercasedTerm, data[key]);
        
      } else if (typeof data[key] === 'string' || data[key] instanceof String) {
        if (data[key].toLowerCase().includes(lowercasedTerm)) {
          result.push(data);
          break; 
        }
      } else if (typeof data[key] === 'object') {
        const filteredObject = filterData(lowercasedTerm, data[key]);
        if (filteredObject.length > 0) {
          result.push({ [key]: filteredObject });
        }
      }
    }
  } else {
    return null;
  }

  return result;
};

export const highlightTerm =(text, term) => {
  const parts = text?.split(new RegExp(`(${term})`, 'gi'));
  return parts?.map((part, index) => 
    part.toLowerCase() === term.toLowerCase() ? 
    <span key={index} className="bg-primary-default text-white">{part}</span> : 
    part
  );
}

