import React from 'react'
import { Link } from 'react-router-dom'

const ImageCard = ({header, subHeading, paragraph, bg, url, linkTitle}) => {
  return (
    <>
      <div className='rounded-lg p-4 bg-white shadow-xl mb-5'>
              <div 
              className='w-full h-[260px] 800px:h-[240px] lg:h-[260px] 1200px:h-[280px] bg-black rounded-lg '
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
                backgroundRepeat:"no-repeat"
              }}
              ></div>

            <h3 className="font-[500] text-[20px] text-[#33709E] mt-5 mb-1 font-sans">
              {header}
            </h3>
            <h5 className="font-[500] text-[14px] text-[#33709E] mb-2 font-sans">
              {subHeading}
            </h5>
            <p className="font-[400] text-[15px] mb-5">{paragraph}</p>

            { linkTitle && <Link to={url} className="bg-[#BE375F] px-6 py-3 rounded-[25px] my-2 inline-block text-gray-50 font-[500] capitalize cursor-pointer hover:bg-transparent hover:border hover:border-[#BE375F] hover:text-[#BE375F] transition-all">
              {linkTitle} 
            </Link>}
          </div>
    </>
  )
}

export default ImageCard
