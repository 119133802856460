import React, { useState } from 'react'
import { FiSearch } from 'react-icons/fi'

const Search = ({onSearch}) => {
    const [searchTerm, setSearchTerm] = useState(null);

    const handleTextChange = (e)=>{
        if(e?.target?.value === ''){onSearch(null)}
        setSearchTerm(e.target.value);
    }
    
  return (
    <>
        <div className="flex">
            <span className="bg-primary-light flex shadow-md  items-center rounded-l-xl">
                <input
                type="text"
                value={searchTerm}
                onChange={(e)=>handleTextChange(e)}
                className="outline-none px-4 bg-transparent focus:scale-[1.06] transition-all duration-500"
                placeholder="Search..."
                />
            </span>
            <span 
            className="bg-primary-default shadow-md p-3 flex items-center justify-center rounded-r-xl cursor-pointer hover:scale-x-[1.06] transition-all duration-500"
            onClick={()=>onSearch(searchTerm)}>
                <FiSearch color="#fff"  className='hover:scale-[1.06] transition-all duration-500'/>
            </span>
        </div>
    </>
  )
}

export default Search
