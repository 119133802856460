import { contactInputs } from "../data/validate";

export const checkIsEmpty = (values) => {
  for (let key in values) {
    if (values[key] === "") {
      return {
        status: true,
        message: `Please fill in the ${key} field`,
      };
    }
  }
  return {
    status: false,
    message: "",
  };
};

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
};

export const validateContactDetails = (values) => {
  const errors = {};
  const { fullName, email, phone } = values;

  if (!fullName.match(contactInputs.fullName.pattern)) {
    errors.fullName = contactInputs.fullName.message;
  }

  if (!email.match(contactInputs.email.pattern)) {
    errors.email = contactInputs.email.message;
  }

  if (!phone.match(contactInputs.phone.pattern)) {
    errors.phone = contactInputs.phone.message;
  }

  return errors;
};
