import React from 'react'
import Layout from '../../components/layout/Layout'
import HeroBanner from '../../components/Hero'
import CyberSecurity from '../../components/cybersecurity/CyberSecurity'
import banner from "../../assets/images/Rectangle21.png";

const CyberSecurityPage = () => {
  return (
    <Layout pageTitle={'Services - Cybersecurity | jmmisa.ng'}>
    {/* banner section */}
    <HeroBanner
      title="CYBER SECURITY & SIEM ENGINEERING"
      subText="We provide consulting, cybersecurity and cloud computing solutions through our IT service."
      showButton={false}
      bannerImage={banner}
      gradient="light"
    />
    <CyberSecurity />
  </Layout>
  )
}

export default CyberSecurityPage
