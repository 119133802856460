import { MdError } from "react-icons/md";
export const TextInputComponent = ({
  type,
  placeholder,
  values,
  setValues,
  name,
  error = {},
}) => {
  const changeHandler = (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  return (
    <div className="flex flex-col gap-2">
      {error[name] && (
        <p className="text-red-500 flex items-center gap-2">
          <span>
            <MdError />
          </span>
          <span>{error[name]}</span>
        </p>
      )}
      <input
        type={type}
        placeholder={placeholder}
        value={values[name]}
        onChange={changeHandler}
        required={true}
        className={`px-4 py-2 rounded-xl hover:border-primary-default focus:border-primary-dark focus:border-[1.5px]  outline-none border border-gray-300 ${
          error[name] && "border-red-500"
        }`}
      />
    </div>
  );
};

export const TextAreaComponent = ({ placeholder, values, name, setValues }) => {
  const changeHandler = (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  return (
    <textarea
      placeholder={placeholder}
      value={values[name]}
      onChange={changeHandler}
      rows={5}
      className="px-4 py-2 rounded-xl border border-gray-300"
    />
  );
};
