import { Icon } from "@iconify/react";
// import { BsShieldLock } from "react-icons/bs";

const customizationIcon = (
  <Icon
    icon="carbon:data-quality-definition"
    className="text-primary-default "
    fontWeight={800}
    width="3rem"
  />
);
const innovationIcon = (
  <Icon
    icon="iconoir:sun-light"
    className="text-primary-default "
    fontWeight={700}
    width="3rem"
  />
);

const qualityAssuranceIcon = (
  <Icon
    icon="material-symbols:assured-workload-outline"
    className="text-primary-default "
    fontWeight={700}
    width="3rem"
  />
);

const collaborationIcon = (
  <Icon
    icon="openmoji:collaboration"
    className="text-primary-default  "
    fontWeight={700}
    width="3rem"
  />
);

const supportIcon = (
  <Icon
    icon="material-symbols-light:engineering-outline"
    className="text-primary-default "
    fontWeight={700}
    width="3rem"
  />
);

export const software_features = [
  {
    icon: customizationIcon,
    subHeading: "Customization",
    paragraph:
      "We understand that every business is unique, which is why we take the time to fully understand your requirements and objectives. Our team will work closely with you to develop a solution that perfectly aligns with your vision.",
      url:"/services/software-development",
  },
  {
    icon: innovationIcon,
    subHeading: "Innovation",
    paragraph:
      "We stay at the forefront of technological advancements and incorporate innovative features and functionalities into our software solutions. This ensures that you're not just getting a solution for today, but one that can adapt and grow with your business into the future.",
      url:"/services/software-development",
  },
  {
    icon: qualityAssuranceIcon,
    subHeading: "Quality Assurance",
    paragraph:
      "Our rigorous testing procedures guarantee the reliability, performance, and security of your software. We leave no stone unturned in ensuring that your solution meets the highest standards of quality.",
      url:"/services/software-development",
  },
  {
    icon: collaborationIcon,
    subHeading: "Collaboration",
    paragraph:
      "We believe in transparent communication and collaboration throughout the development process. You'll have full visibility into the progress of your project and ample opportunities to provide feedback and input along the way.",
      url:"/services/software-development",
  },
  {
    icon: supportIcon,
    subHeading: "Support",
    paragraph:
      "Our commitment to your success doesn't end with the deployment of your software. We provide ongoing support and maintenance to ensure that your solution continues to perform optimally and evolve with your business.",
    url:"/services/software-development",
  },
];
