import bg1 from '../../assets/images/Rectangle10.png'
import bg2 from '../../assets/images/Rectangle18.png'
import bg3 from '../../assets/images/Rectangle19.png'
import bg4 from '../../assets/images/Rectangle20.png'


export const infoItems = [
    {
        bg:bg1,
        header:'Information Technology',
        subHeading:"Mobile and Web Applications",
        paragraph:"Our staff are highly skilled with many years of active professional engagement in their domains of practice. Using state of the art technological tools and highly secured algorithms, we help organizations in various aspect of Information Technology requirements. We represent a one-stop shop for web and mobile application development, software development, content management, quality assurance and even training.",
        urlTitle: "Contact us",
        url:"/contact"
    },
    {
        bg:bg2,
        header:'User Interface and Interaction Design',
        subHeading:"Mobile and Web Applications",
        paragraph:"JM MiSa International Nigeria Limited, a branch of JM MiSa Inc, USA, helps solve common design problems irrespective of the platform, operating system, or domain, the Kenope UX/UI team is adept at handling user experience (UX) challenges. Our approaches includes usability testing to synthesize user expectation, domain-specific usability research, domain-specific design and redesign, wireframing/prototyping, development and testing.",
        urlTitle: "Contact us",
        url:"/contact"
    },
    {
        bg:bg3,
        header:'Database Administration',
        subHeading:"Where We Build Your Visions",
        paragraph:"The administration (or management) of database management systems is a crucial activity in optimizing results and return on investment. JM MiSa International Nigeria Limited provides full Database Administration services including database creation, mapping, performance analysis, user analysis, auditing, database design and system tuning to help clients measure the ongoing success of their database strategies.",
        urlTitle: "Contact us",
        url:"/contact"
    },
    {
        bg:bg4,
        header:'Network Infrastructure Management',
        subHeading:"Where We Build Your Visions",
        paragraph:"Companies run on many interconnected business components, from physical infrastructure to employees and customers who drive income generation. We help keep these areas in sync and effective, by taking the stress off you so that your business can thrive. With a management point for all of your IT needs, we will provide a cost effective streamlining of your business with the latest technologies incorporating the needs of your customer, workforce and your IT infrastructure goals.",
        urlTitle: "Contact us",
        url:"/contact"
    },
]