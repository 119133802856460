import { BsShieldLock } from "react-icons/bs";
import { MdManageHistory } from "react-icons/md";
import { PiChalkboardSimpleThin } from "react-icons/pi";
import { GrCloudComputer } from "react-icons/gr";
import { PiDesktopTowerThin } from "react-icons/pi";
import { SiDatabricks } from "react-icons/si";
import { PiShieldStarThin } from "react-icons/pi";
import { LuBookKey } from "react-icons/lu";

const shieldIcon = <BsShieldLock size={45} className="text-[#33709E] mb-4" />;

const manageHistory = (
  <MdManageHistory size={45} className="text-[#33709E] mb-4" />
);

const chalkBoard = <PiChalkboardSimpleThin size={45} className="text-[#33709E] mb-4" />;

const cloudeCOmputer = (
  <GrCloudComputer size={45} className="text-[#33709E] mb-4" />
);
const starShield = (
  <PiShieldStarThin size={45} className="text-[#33709E] mb-4" />
);
const desktopTower = (
  <PiDesktopTowerThin size={45} className="text-[#33709E] mb-4" />
);

const dataBrikcs = (
  <SiDatabricks size={45} className="text-[#33709E] mb-4" />
);
const bookKey = (
  <LuBookKey size={45} className="text-[#33709E] mb-4" />
);

export const dataProtection = {
    header:"Data Protection",
    paragraph:"Data protection, privacy, and safety are important to the lon-term success of every firm. We provide safe and reliable cybersecurity solutions, ensuring that your information is completely safe from disclosure, unauthorized access, illegal use, unneeded modification, disruption, recording, inspection, and destruction. Our solution includes leveraging splunk solutions to build protections against data exfiltration, DDos, and data compromise.gy",
    url:"/services/cybersecurity"
 }

 export const SIEM = {
    header: "Security Information & Event Management(SIEM)",
    paragraph:"Big data is the new norm for decision-making. However, without proper search and monitoring facilities, using big data effectively can be difficult. JM MiSa International Nigeria Limited offer you the chance to get exceptional splunk solutions for your firm to help engage with and effectively utilize the big data. Our services in this domain include.",
    url:"/services/cybersecurity",

    items:[
        {
            icon:chalkBoard,
            subHeading:"SIEM Implementation",
            paragraph:"We offer design, building and implementation of splunk SIEM platforms both on on-premises and cloud. Our services also include implementation of hybrid structure, or migration from on-premises to BYOL Cloud strategy.",
            url:"/services/cybersecurity"
        },
        {
            icon:manageHistory,
            subHeading:"Use Cases Development",
            paragraph:"We assist your organization to build both operation and secuirty use casesby carring our extensive analysis of your business, affliates and partners with the aim of synthesizing your attack surfaces and vectors. Our implementation team then builds up several knowledge objects to help you conduct most effective, efficient and cost-saving strategies. Our aim is to assit you to build up capabilities in the prevention, detection, responding, and recoverying from threats and attacks.",
            url:"/services/cybersecurity"
        },
        {
            icon:cloudeCOmputer,
            subHeading:"Adequate Logging",
            paragraph:"Effective logging implementations by create extensive integrations of your security tools and devices, applications, and on-premises and cloud infrastructure with Splunk Technology for deeper and innovative visibility into your environment",
            url:"/services/cybersecurity"
        },
        {
            icon:desktopTower,
            subHeading:"Splunk Administration and Engineering",
            paragraph:"We offer cost-saving administration services and engineering implementations across all platforms – Windows, MAC, Linux OS-es",
            url:"/services/cybersecurity"
        },
        {
            icon:dataBrikcs,
            subHeading:"Data onboarding services",
            paragraph:"We offer data on-boarding services, which apart from creating inputs on your systems and assets under scope, includes data normalization/parsing, and obfuscation of sensitive data",
            url:"/services/cybersecurity"
        },

        {
            icon:shieldIcon,
            subHeading:"Incident Response",
            paragraph:"Our teams leverage Splunk Technology and Crowd Strike Falcon to handle all Security and Privacy Incidents, ranging from building alerts, monitoring alerts, investigation and triaging of alerts, containment, and closing",
            url:"/services/cybersecurity"
        },
        {
            icon:bookKey,
            subHeading:"Ransomware Playbooks & Table-Top Exercises/Live Fire Exercises",
            paragraph:"Contact us to design and create extensive ransomware playbooks that can protect your organization in case of ransomware incidents. We help safeguard your critical assets by building highly efficient playbooks, and conducting table-top exercises/live fire exercises that challenges your staff to take actions when necessary",
            url:"/services/cybersecurity"
        },
        {
            icon:starShield,
            subHeading:"Security Orchestration and Automation",
            paragraph:"JM MiSa International Nigeria Limited can help you build automated responses to security incidents leveraging Splunk SOAR or Palo Alto XSOAR platforms.",
            url:"/services/cybersecurity"
        },
    ]
 }