import React from "react";
import ServiceCard from "../servicecard/ServiceCard";

const Services = ({ services }) => {
  const { header, subHeading, paragraph, serviceItems } = services;
  return (
    <div className="bg-[#f5faff] py-[45px]">
      <div className="w-[87%] mx-auto ">
        <div className="w-full mb-10">
          {services && (
            <>
              <h1 className="font-[500] text-[35px] text-[#33709E] my-3 font-sans">
                {header}
              </h1>
              <h5 className="font-[500] text-[18px] text-[#33709E] mb-3 font-sans">
                {subHeading}
              </h5>
              <p>{paragraph}</p>
            </>
          )}
        </div>

        <div className="w-full grid grid-cols-1 gap-10  830px:grid-cols-2 830px:w-[770px] lg:w-[910px] 1200px:w-[1010px] mx-auto 1700px:grid-cols-3 1700px:w-[1515px]">
          {serviceItems &&
            serviceItems.map((item, index) => (
              <ServiceCard
                key={index}
                icon={item.icon}
                heading={item.heading}
                subHeading={item.subHeading}
                paragraphs={item.paragraph}
                linkTitle={"Learn More"}
                url={item.url}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
