import { useRef } from "react";
import training_banner from "../../assets/images/training_banner.png";
import training_section from "../../assets/images/training_section.png";
import CallToActionBanner from "../../components/CallToActionBanner";

import HeroBanner from "../../components/Hero";
import SectionBanner from "../../components/SectionBanner";
import Layout from "../../components/layout/Layout";
import { training_details } from "../../utils/data/training-data";

const ITTraining = () => {
  const ref = useRef(null);

  return (
    <Layout pageTitle={'Services - Training | jmmisa.ng'}>
      <HeroBanner
        title="JM IT Training School"
        subText="An IT training department in JM MiSa Inc which offers training in various IT Domains."
        showButton={false}
        bannerImage={training_banner}
      />
      <div className="mt-20">
        <SectionBanner
          content="Data protection, privacy, and safety are important to the long-term
            success of every firm. We provide safe and reliable cybersecurity
            solutions, ensuring that your information is completely safe from
            disclosure,unauthorized access, illegal use, unneeded modification,
            disruption, recording, inspection, anddestruction. Our solutions
            include leveraging Splunk solutions to build protections against
            data exfiltration, DDoS, and data compromise.gy."
          title="Data Protection"
          bannerImg={training_section}
        />
      </div>
      <div ref={ref} className="flex flex-col my-20 max-w-[80vw] mx-auto ">
        {training_details.map((detail, index) => (
          <div key={detail.title}>
            <h1 className="text-xl font-semibold my-5">{detail.title}</h1>
            <p className="my-5">{detail.paragragh}</p>
            <h3 className="text-md font-semibold my-2">{detail.subTitle}</h3>
            <ul className="list-disc list-inside">
              {detail.features.map((feature, index) => (
                <li className="my-2" key={index}>
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <CallToActionBanner />
    </Layout>
  );
};

export default ITTraining;
