import HeroBanner from "../../components/Hero";
import Layout from "../../components/layout/Layout";
import service_banner from "../../assets/images/service_banner.png";
import Services from "../../components/home/Services";
import { services_data } from "../../utils/data/service-data";
import { Outlet } from "react-router";
import { useRef } from "react";

// This is the homepage
const ServicePage = () => {
  const ref = useRef(null);

  const navigateFunction = () => {
    window.scroll({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Layout pageTitle={'Services | jmmisa.ng'}>
        <HeroBanner
          title="Our services"
          subText="Our services encompasses everything we do to empower individuals and businesses to succeed."
          buttonText="Learn More"
          bannerImage={service_banner}
          navigateFunction={navigateFunction}
        />
        <main ref={ref} className="mt-20">
          <Services services={services_data} />
        </main>
      </Layout>
      <Outlet />
    </>
  );
};

export default ServicePage;
