import React from "react";
import protection_section from "../../assets/images/protection_section.png";
import dataBg from "../../assets/images/data-bg.png";
import Rectangle23 from "../../assets/images/protection_section.png";
import { SIEM, dataProtection } from "../../utils/data/cybersecurity-data";
import ServiceCard from "../servicecard/ServiceCard";

const CyberSecurity = () => {
  return (
    <>
      <div className="w-full py-[5rem] bg-[#f5faff]">
        <div className="w-[87%] mx-auto">
          <div
            className="w-full flex flex-col items-center 
            1200px:flex-row 1200px:justify-between mb-[13.5rem] 1200px:mb-[5rem]"
          >
            <div
              className="relative bg-gradient-to-r from-[#33709E] via-[#487AA8] to-[#BC84F3] 
              rounded-lg w-[75%] h-[180px] 400px:h-[200px] sm:h-[300px] md:h-[380px] 800px:h-[450px] 
              lg:h-[550px] 1200px:w-[460px] 1200px:h-[360px]"
            >
              <img
                src={Rectangle23}
                alt=""
                className="absolute top-[3rem] 800px:top-[3.75rem] left-[2rem] sm:left-[2.5rem] 
                800px:left-[4rem] lg:left-[8rem] 1200px:left-[4rem]"
              />
            </div>

            <div className="w-[75%] order-first 1200px:order-last 1200px:w-[50%]">
              <h1
                className="capitalize text-gray-900 font-sans font-[600] 
                text-[2.25rem] mb-4"
              >
                {SIEM.header}
              </h1>

              <p className="text-gray-800 first-letter:capitalize mb-6">
                {SIEM.paragraph}
              </p>
            </div>
          </div>
          <div className='items-stretch w-full grid grid-cols-1 gap-10  830px:grid-cols-2 830px:w-[770px] lg:w-[910px] 1200px:w-[1010px] mx-auto 1700px:grid-cols-3 1700px:w-[1515px]'>
            {SIEM?.items && SIEM.items.map((item, index)=>(    
                  <ServiceCard 
                    key={index}
                    icon={item.icon}
                    subHeading={item.subHeading}
                    paragraphs={item.paragraph}
                    hasLink={false}
                  />
              ))}         
          </div>
        </div>
      </div>

      <div
        className="w-full py-[5rem] bg-gray-800"
        style={{
          backgroundImage: `url(${dataBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center 10%",
        }}
      >
        <div
          className="w-[87%] mx-auto flex flex-col items-center 
          1200px:flex-row 1200px:justify-between mb-[13.5rem] 1200px:mb-[5rem]"
        >
          <div
            className="relative bg-gradient-to-r from-[#33709E] via-[#487AA8] to-[#BC84F3] 
            rounded-lg w-[75%] h-[180px] 400px:h-[200px] sm:h-[300px] md:h-[380px] 800px:h-[450px] 
            lg:h-[550px] 1200px:w-[460px] 1200px:h-[360px]"
          >
            <img
              src={protection_section}
              alt=""
              className="absolute top-[3rem] 800px:top-[3.75rem] left-[2rem] sm:left-[2.5rem] 
              800px:left-[4rem] lg:left-[8rem] 1200px:left-[4rem]"
            />
          </div>

          <div className="w-[75%] order-first 1200px:order-last 1200px:w-[50%]">
            <h1
              className="capitalize text-gray-50 font-sans font-[600] 
              text-[2.25rem] mb-4"
            >
              {dataProtection.header}
            </h1>
        
            <p className="text-gray-50 first-letter:capitalize mb-6">
              {dataProtection.paragraph}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CyberSecurity;
