import React from 'react'
import { Link } from 'react-router-dom';
import { highlightTerm } from '../../utils/helperFunctions/helperFunctions';

const DisplaySearchdata = ({term, data}) => {
  return (
    <div className='fixed left-0 top-[90px] w-full h-[90vh]  bg-[#25242423] backdrop-blur-sm z-50'>
      <div className='w-full h-full flex items-center justify-center'>
        <div className={`${data.length > 2 ?'h-[430px]' : 'h-fit'} w-[64%]  overflow-y-auto bg-gray-50 rounded-md py-8 px-5`}>
            <h2 className='text-[17px] font-[700] text-primary-default my-2 capitalize'>search result for: "{term}"</h2>
            <ul className='w-full'>
                {   
                    data?.length  > 0 ? data.map((item, index) => (
                            
                        <li key={index} title='Click to read more'>{console.log(item.paragraphs)}
                             <Link to={item.url} className='block p-4 bg-gray-200 rounded-md mb-3 text-[14px] hover:border hover:border-primary-default'>
                             {item.header && <>
                                    <h5 className='font-[700] text-primary-default'>{highlightTerm(item.header, term)}</h5>
                                </> }
                             {item.title && <>
                                    <h5 className='font-[700] text-primary-default'>{highlightTerm(item.title, term)}</h5>
                                </> }
                                {item.subHeading && <>
                                    <h5 className='font-[500] text-primary-default'>{highlightTerm(item.subHeading, term)}</h5>
                                </> }
                                {item.subTitle && <>
                                    <h5 className='font-[500] text-primary-default'>{highlightTerm(item.subTitle, term)}</h5>
                                </> }
                                {!item.subHeading && !item.subTitle && item.subTitle && !item.title ?<>
                                    <h5 className='font-[500] text-primary-default'>About Us</h5>
                                </> : null }
                                
                                {item.paragraph && <p>{highlightTerm(item.paragraph, term)}</p>}
                                {item?.paragraphs && item?.paragraphs.map((p, i)=>(
                                    <p key={i}>{highlightTerm(p, term)}</p>
                                )) }
                                {item.text && <p>{highlightTerm(item.text, term)}</p>}
                                {item?.features && item?.features.map((p, i)=>(
                                    <p key={i}>{highlightTerm(p, term)}</p>
                                )) }
                                
                            </Link>
                        </li>
                    )) : <li className='w-full text-center'>No Search Match For "{term}"</li>
                }
            </ul>
        </div>
      </div>
    </div>
  )
}

export default DisplaySearchdata
