import { BiLoaderAlt } from "react-icons/bi";
const buttonTypes = {
  primary: "bg-primary-default text-white disabled:bg-slate-300",
  light: "bg-info-default text-primary-dark",
  success: "bg-green-500 text-white",
};

const ButtonComponent = ({
  title,
  type,
  clickHandler,
  disabled = false,
  loader = false,
}) => {
  return (
    <>
      {loader ? (
        <span className="text-primary-default">
          <BiLoaderAlt className="animate-spin" />
        </span>
      ) : (
        <button
          onClick={clickHandler}
          disabled={disabled}
          className={`${buttonTypes[type]}  px-16 font-medium py-2 rounded-3xl`}
        >
          {title}
        </button>
      )}
    </>
  );
};

export default ButtonComponent;
