export const contactInputs = {
  fullName: {
    pattern: /^[a-zA-Z\s]*$/,
    message: "Please enter a valid name",
  },
  email: {
    pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    message: "Please enter a valid email",
  },
  phone: {
    pattern: /^\+?([0-9]{1,3})[-.\s]?([0-9]{3,})[-.\s]?([0-9]{4,})$/,
    message: "Please enter a valid phone number",
  },
};
