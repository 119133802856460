import { useEffect } from "react";
import { GrLocation } from "react-icons/gr";
import { GoMail } from "react-icons/go";
import { FiPhone } from "react-icons/fi";
import ReCAPTCHA from "react-google-recaptcha";

import HeroBanner from "../../components/Hero";
import Layout from "../../components/layout/Layout";

import contact_banner from "../../assets/images/contact_banner.png";
import {
  TextAreaComponent,
  TextInputComponent,
} from "../../components/tags/Input";
import { useRef, useState } from "react";
import ButtonComponent from "../../components/tags/Button";
import { sendContactEmail } from "../../services/email";
import {
  checkIsEmpty,
  scrollToTop,
  validateContactDetails,
} from "../../utils/helperFunctions";
import { successNotification } from "../../utils/toast";

const initialData = {
  fullName: "",
  email: "",
  phone: "",
  message: "",
};

const AddressComponent = ({ icon, text }) => {
  return (
    <div className="flex gap-5   items-center">
      <span className="text-primary-default">{icon}</span>
      <p className="text-primary-dark">{text}</p>
    </div>
  );
};

// This is the homepage
const ContactPage = () => {
  const [values, setValues] = useState(initialData);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    try {
      if (recaptchaRef.current) {
        recaptchaRef.current.executeAsync(
          process.env.REACT_APP_RECAPTCHA_TOKEN
        );
      }
    } catch (err) {}
  }, []);

  const isEmpty = checkIsEmpty(values);

  const navigateFunction = () => {
    window.scroll({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  const contactSupport = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const token = await recaptchaRef.current.getValue();
      if (!token)
        throw new Error(" Could not verify captcha. Please try again. ");

      const errors = validateContactDetails(values);

      if (Object.keys(errors).length > 0) {
        throw errors;
      }
      const response = await sendContactEmail(values);
      if (response.messageId) {
        setValues(initialData);
        successNotification("Email sent successfully");
        setError({});
        scrollToTop();
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout pageTitle={'Contact Support | jmmisa.ng'}>
      <HeroBanner
        title="contact"
        subText="Have questions or feedback? Reach out to us easily! Contact our dedicated team through the provided form or email address, and we'll get back to you promptly. Your input matters to us"
        buttonText="Contact now"
        bannerImage={contact_banner}
        navigateFunction={navigateFunction}
      />
      <div className="flex max-w-[85vw] md:flex-row flex-col mx-auto md:h-screen my-20 items-center justify-center">
        <div className="flex gap-20 md:flex-row flex-col ">
          <section className="flex-1 text-primary-dark  flex flex-col gap-5">
            <h1 className="md:text-4xl text-3xl text-primary-default font-bold">
              Contact Us
            </h1>
            <p>
              Have some queries regarding our services? Let us know through our
              contact support form given below. Our team of IT contractors is
              available 24/7 to answer your queries regarding our enterprise
              solutions, security solutions and all other services. Similarly,
              you may request a free quote from our team online as well. Just
              leave us a message and we will get back to you as soon as
              possible. In the meanwhile, check out our service pages for a
              better understanding of what we offer.
            </p>
            <p>
              Visit our office and sit down with us for a cup of tea, discussing
              your firms’ IT requirements in detail.
            </p>
            <div className="flex flex-col gap-3">
              <AddressComponent
                icon={<GrLocation />}
                text="Suite 33 Amaden Plaza Mabushi Abuja"
              />
              <AddressComponent icon={<GoMail />} text="info@jmmisa.com" />
              <AddressComponent icon={<FiPhone />} text="+2349035518652" />
            </div>
          </section>
          <section ref={ref} className="flex-1 shadow-md md:p-10 p-3">
            <form className=" flex flex-col gap-6">
              <h2 className="text-primary-default font-bold">
                Connect with us to discover how we can be of help to you.
              </h2>
              <div className="flex flex-col gap-5">
                <TextInputComponent
                  type="text"
                  placeholder="Enter Your Full Name"
                  values={values}
                  name="fullName"
                  setValues={setValues}
                  error={error}
                />
                <TextInputComponent
                  type="email"
                  placeholder="Email"
                  values={values}
                  name="email"
                  setValues={setValues}
                  error={error}
                />
                <TextInputComponent
                  type="phone"
                  placeholder="Phone number"
                  values={values}
                  name="phone"
                  setValues={setValues}
                  error={error}
                />
                <TextAreaComponent
                  placeholder="Message..."
                  name="message"
                  values={values}
                  setValues={setValues}
                />
                <div className=" mx-auto">
                  <ReCAPTCHA
                    size="invisible"
                    ref={recaptchaRef}
                    sitekey={`${process.env.REACT_APP_RECAPTCHA_TOKEN}`}
                  />
                  <ButtonComponent
                    clickHandler={contactSupport}
                    title={"Submit"}
                    type={"primary"}
                    disabled={isEmpty.status}
                    loader={loading}
                  />
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
