import React from "react";
import {
  address,
  about,
  footerLinks,
  footerIcons,
} from "../../utils/data/footer-data";
import footerLogo from "../../assets/images/footerLogo.png";
import { Link } from "react-router-dom";
import { AiOutlineCopyright } from "react-icons/ai";
import { CiMail } from "react-icons/ci";
import { HiOutlinePhone } from "react-icons/hi2";
import { CiClock2 } from "react-icons/ci";
import { CiCalendarDate } from "react-icons/ci";
import { GrLocation } from "react-icons/gr";

const Footer = () => {
  return (
    <div className="mb-0 bg-[#33709E] w-full">
      <div className="w-[87%] mx-auto grid grid-cols-1 gap-[15px] md:grid-cols-2 md:gap-[20px] lg:grid-cols-4 lg-gap-[20px] pt-[110px] pb-[55px]">
        <div className="mr-[40px]">
          <Link className="cursor-pointer">
            <img
              src={footerLogo}
              alt="JMMISA Logo"
              className="w-[150px] h-[90px] mb-[30px]"
            />
          </Link>
          <p className="text-[14.5px] text-gray-300 font-[300] mb-3">{about}</p>

          <div className="w-full flex">
            {footerIcons &&
              footerIcons.map((i, index) => (
                <Link
                  target="_blank"
                  key={index}
                  to={i.url}
                  className=" w-8 h-8 rounded-full bg-[#f5faff] flex items-center justify-center mr-2.5 transition-all ease-out hover:scale-[1.05]"
                >
                  {i.icon}
                </Link>
              ))}
          </div>
        </div>

        {footerLinks &&
          footerLinks.map((i) => (
            <div key={i.id}>
              <h4 className="capitalize text-[20px] text-gray-50 font-[500] mb-8">
                {i.header}
              </h4>

              <ul>
                {i.links &&
                  i.links.map((link, index) => (
                    <li key={index} className="  py-3">
                      <Link
                        to={link.url}
                        className="relative inline text-[16px] text-gray-300 font-[300] capitalize before:w-[0%] before:h-[2px] before:bg-gray-50 before:block before:absolute before:bottom-[-3px] hover:before:w-[85%] hover:before:transition-all ease-out hover:before:duration-[1s]"
                      >
                        {link.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          ))}

        <div>
          <h4 className="capitalize text-[20px] text-gray-50 font-[500] mb-8">
            {address.header}
          </h4>
          <ul>
            <li className="text-[15px] text-gray-300 font-[300] capitalize py-3">
              <GrLocation className="mr-3 text-gray-300 inline-block" />
              <span>{address.addr}</span>
            </li>
            <li className="text-[16px] text-gray-300 font-[300]  py-3">
              <CiMail className="mr-3 text-gray-300 inline-block" />
              <span>{address.email}</span>
            </li>
            <li className="text-[16px] text-gray-300 font-[300] capitalize py-3">
              <HiOutlinePhone className="mr-3 text-gray-300 inline-block" />
              <span>{address.phone}</span>
            </li>
            <li className="text-[16px] text-gray-300 font-[300] capitalize py-3">
              <CiCalendarDate className="mr-3 text-gray-300 inline-block" />
              <span>{address.work}</span>
            </li>
            <li className="text-[16px] text-gray-300 font-[300] capitalize py-3">
              <CiClock2 className="mr-3 text-gray-300 inline-block" />
              <span>{address.time}</span>
            </li>
          </ul>
        </div>
      </div>

      <hr className="w-[87%] mx-auto h-[0.5px] my-4 bg-gray-100 border-0" />

      <div className="w-[87%] mx-auto flex items-center">
        <p className="text-gray-300 w-full text-center capitalize py-[25px]">
          copyright <AiOutlineCopyright className="inline-block" />{" "}
          {new Date().getFullYear()} JM Misa Inc. powered by JM Misa Inc.{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
