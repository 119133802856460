import HeroBanner from "../../components/Hero";
import Layout from "../../components/layout/Layout";

import about_banner from "../../assets/images/about_banner.png";
import About from "../../components/about/About";

// This is the homepage
const AboutPage = () => {
  return (
    <Layout pageTitle={'About Us | jmmisa.ng'}>
      <HeroBanner
        title="about us"
        subText="Our services encompasses everything we do to empower individuals and businesses to succeed."
        buttonText="Contact us"
        showButton={false}
        bannerImage={about_banner}
      />
      <About />
    </Layout>
  );
};

export default AboutPage;
